import React from 'react'
import styled from 'styled-components';
import Layout from '../components/layout';
import withLocation from "../components/withLocation"
import { useEffect } from 'react';
import axios from 'axios'
import Loader from 'react-loader-spinner';
import { colours } from '../style/colours';
import { navigate } from 'gatsby';

const VerifyTransactionPage = ({search}) => {

    const { 
        status,
        name,
        email,
        hash,
        order_id,
        transaction_id,
        reasonCode,
        reasonDescription,
        responseCode,
        total,
        D
    } = search

    useEffect(() => {

        const verifyTransaction = async () => {
            try {
                let postRes = await axios.post('/.netlify/functions/verify-transaction', 
                JSON.stringify({
                    status,
                    name,
                    email,
                    hash,
                    order_id,
                    transaction_id,
                    reasonCode,
                    reasonDescription,
                    responseCode,
                    total,
                    D
                }))
                if (postRes.data.success) {
                    const {
                        status,
                        name,
                        email,
                        order_id,
                        total
                    } = postRes.data
                    navigate(`/order-confirmation?status=${status}&name=${name}&email=${email}&order_id=${order_id}&total=${total}`, { replace: true })
                    return
                }
                navigate('/order-failed', { replace: true })
            }catch(err){
                alert("Something went wrong.")
            }
        }
        verifyTransaction()
    }, [])

    return (
        <Layout>
            <Container>
                <Loader 
                    type="Oval"
                    width={30}
                    height={30}
                    color={colours.maroonDark}
                />
                <p>Finishing up...</p>
            </Container>
        </Layout>
    )
}

export default withLocation(VerifyTransactionPage) 

const Container = styled.section`
    display: flex;
    height: 70vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;